@import "../../../../variables.scss";

.glb_list01 {
  margin-bottom: 8px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  > li {
    background: #fff;
    > a {
      display: block;
      padding-bottom: 13px;
    }
    + li {
      border-top: 1px solid #e6e6e6;
    }
    &:last_child.glb_list01_btn {
      padding-top: 16px;
      > a {
        display: inline-block;
      }
    }
    &.type_error {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.6;
      padding: 15px 16px 13px;
      color: #ff3333;
      background: rgba(255, 51, 51, 0.1);
    }
  }
  &_in {
    display: flex;
    align-items: center;
    + .glb_list01_img {
      padding-top: 0;
    }
    .type_end {
      margin-left: auto;
    }
    > .glb_btn03:last_child {
      margin-right: 16px;
    }
    .glb_list01_img {
      width: 100px;
      padding: 16px 0 16px 16px;
      img {
        width: 100px;
      }
    }
    .glb_list01_text {
      flex: 1;
    }
    .glb_switch01 {
      margin-right: 16px;
      margin-left: auto;
    }
  }
  &_text {
    display: block;
    padding: 17px 16px 16px;
    font-size: 1.7rem;
    line-height: 1.5;
    + .glb_list01_text {
      padding-top: 0;
    }
  }
  &_none {
    text-align: center;
    padding: 50px 16px;
    color: #666;
    font-size: 1.5rem;
  }
  &_zero {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    background: none;
    box-shadow: none;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.8rem;
    line-height: 2;
    &_ttl {
      font-size: 2rem;
      font-weight: bold;
      + .glb_list01_zero_text {
        margin-top: 12px;
      }
    }
    &_text {
      font-size: 1.5rem;
    }
  }
  &_main {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
    padding: 15px 16px 0;
  }
  &_ttl {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
    padding: 14px 16px 0;
    + .glb_box01_form {
      padding-top: 12px;
    }
    &_label {
      color: #666;
      margin-left: 10px;
      font-size: 1.1rem;
      display: inline-block;
      position: relative;
      bottom: 1px;
      border: 1px solid #ccc;
      padding: 2px 5px 1px;
      border-radius: 2px;
    }
  }
  &_sub {
    font-size: 1.4rem;
    line-height: 1.5;
    color: #333333;
    padding: 5px 16px 13px;
  }
  &_info {
    padding: 0 16px 16px;
    &.type_attent {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 60px 16px 64px;
      color: #e51717;
    }
  }
  &_btn {
    text-align: center;
    padding: 0 16px 16px;
  }
  &_cap {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 9px;
  }
  &_help {
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 16px 16px 14px;
    color: #666;
  }
  &_box {
    padding: 0;
  }
  &_img {
    padding: 16px;
    img,
    canvas {
      width: 100%;
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin-right: 12px;
      padding-right: 12px;
      position: relative;
      &::after {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        right: 0;
        width: 1px;
        height: 14px;
        background: rgba(0, 0, 0, 0.1);
        margin-top: -7px;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  &.type_check {
    > li {
      cursor: pointer;
      position: relative;
      opacity: 0.7;
      transition: all 0.1s linear;
      .glb_list01_main,
      .glb_list01_sub {
        padding-left: 50px;
      }
      &::before {
        position: absolute;
        top: 43px;
        left: 18px;
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        margin-top: -10px;
        background: url(/assets/images/common/icon_check_01.svg) no-repeat;
        opacity: 0.5;
        transition: opacity 0.1s linear;
      }
      + li {
        border-top-color: #b3b3b3;
        &.is_check {
          border-top-color: rgba(0, 0, 0, 0.1);
        }
      }
      &.is_check {
        opacity: 1;
        background: #dcf0fa;
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: $mediaW) {
  .glb_list01 {
    text-align: center;
  }
}

@media screen and (min-width: $mediaW) {
  .glb_list01 > li {
    padding-right: calc((100vw - #{$mediaW}) / 2);
    padding-left: calc((100vw - #{$mediaW}) / 2);
  }
  .glb_list01 > li.type_error {
    padding-right: calc((100vw - #{$mediaW} + 32px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
  .glb_list01.type_check > li::before {
    left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
}
