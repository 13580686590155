@import "../../../variables";

.caution {
  @include content-large-bold;
  color: $content-error-main;
}

.inner {
  display: flex;
  gap: 8px;
  align-items: center;
}

.titleBox {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding-top: 16px;
}

.title {
  @include content-xl-large;

  @include mq-down("md") {
    @include content-large-bold;

    br {
      display: none;
    }
  }
}

.titleInner {
  display: flex;
  align-items: center;
  padding: 3.5px 8px 0 8px;
  margin-top: 2px;
  background-color: $bg-neutral-lighter;
  border: 1px solid $border-outline;
  border-radius: 4px;

  @include mq-down("md") {
    width: 115px;
  }
}

.titleInnerText {
  color: $content-neutral-dark;
  text-align: center;
}

.ato {
  @include content-3xs-regular;
  display: block;
  margin-bottom: -8px;
  text-align: left;
}

.day {
  @include content-en-2xl-bold;
  color: $content-error-main;
  text-align: left;
}

.niti {
  @include content-x-small-regular;
  padding-left: 2px;
  text-align: left;
}

.text {
  @include content-base-regular;
  color: $content-neutral-dark;
  letter-spacing: 0.7px;

  @include mq-down("md") {
    @include content-small-regular;
    letter-spacing: 0.65px;

    br {
      display: none;
    }
  }
}

.textDay {
  padding-right: 4px;
  color: $content-error-main;
}

.subText {
  @include content-base-regular;
  padding: 20px 0;
  text-align: right;

  @include mq-down("md") {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 8px;

  @include mq-down("md") {
    flex-direction: column-reverse;
    gap: 16px;
    align-items: stretch;

    > button {
      width: 100%;
      min-width: 100% !important;
    }
  }
}
