@import "../../../../variables";

.card {
  width: 100%;
  background-color: $bg-neutral-white;
  border-radius: 2px;
}

.bottomPaddingNone {
  padding: 8px 24px 0;

  @include mq-down("md") {
    padding: 8px 16px 0;
  }
}

.bottomPaddingDefault {
  padding: 8px 24px;

  @include mq-down("md") {
    padding: 8px 16px;
  }
}
