.input {
  display: block;
  background: #fff;
  border-radius: 3px;
  width: 100%;
  padding: 10px;
  font-size: 1.6rem;
  line-height: 1.5;
  box-sizing: border-box;
  box-shadow: none;
}
