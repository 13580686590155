.glb_btn01 {
  display: inline-block;
  background: #17a1e6;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 14px 24px 13px;
  border-radius: 5px;
  border: 1px solid #17a1e6;
  opacity: 1;
  transition: opacity 0.1s linear;
  text-align: center;
  cursor: pointer;
  &.is_disable {
    cursor: default;
    opacity: 0.6;
  }
  &.type_w {
    width: 100%;
  }
}
