@import "../../../variables";

.inner {
  display: grid;
  gap: 16px;
  padding: 0 24px;

  @include mq-down("md") {
    padding: 0;
  }
}

.title {
  @include content-4xl-bold;
  padding: 8px 0;
  text-align: center;

  @include mq-down("md") {
    @include content-en-2xl-bold;
    @include line-height(30);
  }
}

.description {
  @include content-xl-large;
}

.note {
  @include content-large-regular;

  & > span {
    @include content-large-bold;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
}
