@import "../../variables.scss";

.sec_header01 {
  background: #0088bf url(/assets/images/common/bg_02.avif) no-repeat center
    bottom;
  background-size: cover;
  height: $headerH;
  display: flex;
  &_back {
    height: 52px; // imageサイズ + 14*2
    width: 56px; // imageサイズ + 16*2

    background: url(/assets/images/common/icon_arrow_01.svg) no-repeat 12px 15px;
    background-size: 22px auto;
    cursor: pointer;
    + .sec_header01_text {
      padding: 18px 52px 0 0;
    }
  }
  &_text {
    flex: 1;
    text-align: center;
    font-size: 1.6rem;
    color: #fff;
    padding: 18px 16px 0;
    letter-spacing: 0.05em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (min-width: 560px) {
  .sec_header01 {
    background-size: cover;
  }
}

@media screen and (min-width: $mediaW) {
  .sec_header01 {
    background-image: url(/assets/images/common/bg_04.avif);
    background-position: center center;
    padding-right: calc((100vw - #{$mediaW} + 32px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
  .sec_header01_back {
    margin-left: -14px;
  }
}
