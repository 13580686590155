@import "../../variables.scss";

.sec_video01 {
  padding-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.sec_video01_box {
  width: 100vw;
  height: 100vw;

  position: relative;
  padding-top: 60px;

  &:before {
    display: block;
    content: "";
    width: 80vw;
    height: 80vw;
    position: absolute;
    top: 60px;
    left: 0;
    background-image: url(/assets/images/common/icon_focus_01.svg),
      url(/assets/images/common/icon_focus_02.svg),
      url(/assets/images/common/icon_focus_03.svg),
      url(/assets/images/common/icon_focus_04.svg);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top left, top right, bottom right, bottom left;
    border: 10vw solid rgba(0, 0, 0, 0.4);
  }
}

@media screen and (min-width: $mediaW) {
  .sec_video01_box {
    width: 80vh;
    height: calc(80vh + 60px);
  }
  .sec_video01_box::before {
    width: 60vh;
    height: 60vh;
    border-width: 10vh;
  }
}

.sec_video01_text {
  position: absolute;
  top: 0;

  width: 100%;
  display: flex;
  height: 60px;
  box-sizing: border-box;
  padding-top: 0px;
  justify-content: center;
  align-items: center;

  > span {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
  }
}

.sec_video01_video {
  width: 100%;
  align-self: flex-start;
  vertical-align: bottom;
  background: #000;
  min-height: 200px;
  aspect-ratio: 480 / 480;
}

.animate {
  animation: slide 1s;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(64px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
