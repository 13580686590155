@import "../../variables.scss";

.module {
  position: fixed;
  width: 100%;
  height: 32px;
  bottom: 0;
  color: #9e9e9e;
}

.isDark {
  color: $colorWhite;
}

.list {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;
  gap: 4px;
}

.listItem {
  height: 100%;

  > a {
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 150%;
    color: inherit;
  }
}

@media screen and (min-width: $mediaW) {
  .list {
    justify-content: flex-end;
    padding-inline: 24px;
  }
}
