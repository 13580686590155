@import "../../variables.scss";

.sec_home01 {
  background: #fff;
  position: relative;
  &_ttl {
    min-height: 158px;
    padding: 50px 20px 45px;
    box-sizing: border-box;
    background: #00a4ea url(/assets/images/common/bg_02.avif) no-repeat center
      bottom;
    background-size: auto 217px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.9rem;
    line-height: 1.5;
  }
  &_logout {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  &_external_policy {
    position: absolute;
    top: 12px;
    right: 102px;
  }
  &_text {
    font-size: 1.7rem;
    line-height: 1.7;
    padding: 16px 16px 0;
  }
  &_btn {
    text-align: center;
    padding: 14px 16px 20px;
  }
}
@media screen and (min-width: 560px) {
  .sec_home01_ttl {
    background-size: cover;
  }
}
@media screen and (min-width: $mediaW) {
  .sec_home01_ttl {
    background-image: url(/assets/images/common/bg_04.avif);
    background-position: center center;
  }
  .sec_home01_text {
    text-align: center;
  }
}

// ログアウトボタンは他の画面で出てきたらcomponent化
.glb_btn02 {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 15px 15px 14px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  background-color: transparent; // エンジニア側で追加
  cursor: pointer;
  &.type_xs {
    font-weight: normal;
    font-size: 1.2rem;
    padding: 9px 10px 7px;
  }
}
