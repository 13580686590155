@import "../../../../variables.scss";

.sec_error01 {
  padding-top: $headerH;
}

.glb_list01 {
  margin-bottom: 8px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  > li {
    background: #fff;
    > a {
      display: block;
      padding-bottom: 13px;
    }
    + li {
      border-top: 1px solid #e6e6e6;
    }
  }
  &_text {
    display: block;
    padding: 17px 16px 16px;
    font-size: 1.7rem;
    line-height: 1.5;
    + .glb_list01_text {
      padding-top: 0;
    }
  }
  &_zero {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    background: none;
    box-shadow: none;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.8rem;
    line-height: 2;
    &_ttl {
      font-size: 2rem;
      font-weight: bold;
      + .glb_list01_zero_text {
        margin-top: 12px;
      }
    }
    &_text {
      font-size: 1.5rem;
    }
  }
  &_ttl {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
    padding: 14px 16px 0;
    + .glb_box01_form {
      padding-top: 12px;
    }
    &_label {
      color: #666;
      margin-left: 10px;
      font-size: 1.1rem;
      display: inline-block;
      position: relative;
      bottom: 1px;
      border: 1px solid #ccc;
      padding: 2px 5px 1px;
      border-radius: 2px;
    }
  }
}

@media screen and (min-width: $mediaW) {
  .glb_list01 > li {
    padding-right: calc((100vw - #{$mediaW}) / 2);
    padding-left: calc((100vw - #{$mediaW}) / 2);
  }
  .glb_list01 > li.type_error {
    padding-right: calc((100vw - #{$mediaW} + 32px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
  .glb_list01.type_check > li::before {
    left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
}
