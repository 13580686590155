.ring {
  display: block;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-top: 3px solid #d9d9d9;
  border-right: 3px solid #d9d9d9;
  border-bottom: 3px solid #d9d9d9;
  border-left: 3px solid #148fcc;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  animation: loading 0.9s infinite linear;
}

.ring::after {
  display: block;
  content: "";
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
