@import "../../../../variables";

.primary {
  color: $content-neutral-white;
  background-color: $bg-primary-main;
  border: 1px solid $border-primary-main;
}

.secondary {
  color: $content-primary-main;
  background-color: $bg-neutral-white;
  border: 1px solid $border-primary-main;
}

.button {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  @include component-button-large;
}
