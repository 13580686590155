body,
div,
span,
a,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
main,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  background: transparent;
  vertical-align: baseline;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
}
html {
  font-size: 10px;
}
html,
body {
  height: 100%;
}
body {
  -webkit-text-size-adjust: 100%;
  line-height: 1;
}
article,
aside,
main,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
}
blockquote,
q {
  quotes: none;
}
br {
  letter-spacing: 0;
}
th,
td {
  text-align: left;
}
del {
  text-decoration: line-through;
}
img {
  vertical-align: top;
  border: 0;
}
ins {
  text-decoration: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
blockquote::before,
blockquote::after,
q:before,
q::after {
  content: none;
}
b,
strong {
  font-weight: bold;
}
dfn,
em,
i {
  font-style: normal;
}
ul {
  list-style: none;
}
small {
  font-size: 100%;
}
textarea,
input[type="search"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
button,
input[type="submit"],
input[type="password"] {
  -webkit-appearance: none;
  border: none;
  outline: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
a {
  text-decoration: none;
}
a,
button,
input,
select,
.toggle01,
.toggle02,
.toggleClose01 {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
textarea,
input,
select {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
textarea {
  resize: none;
}
button {
  border-image-width: 0;
  outline-color: none;
  vertical-align: baseline;
}
pre {
  font-family: "Hiragino Kaku Gothic Pro", メイリオ, Meiryo,
    "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  white-space: pre-wrap;
  word-break: break-all;
}
:placeholder-shown {
  color: #7daab3;
}
::-webkit-input-placeholder {
  color: #7daab3;
}
