.section {
  padding-top: 50px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.errorText {
  font-weight: bold;
  color: #e51717;
  padding: 26px 16px 20px;
  font-size: 1.7rem;
  line-height: 1.7;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  padding: 0 16px 16px;

  > a:first-child {
    margin-right: 20px;
  }
}

.outlineLink {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.5rem;
  padding: 11px 18px 10px;
  border-radius: 4px;
  white-space: nowrap;
  color: #148fcc;
  cursor: pointer;
  border: 1px solid rgba(20, 143, 204, 0.5);
  min-width: 100px;
}
