@import "./variables.scss";

body {
  display: flex;
  font-size: 1.4rem;
  font-family: $fontFamily;
  color: $colorText;
  flex-direction: column;
  min-height: 100%;
}
textarea,
input,
button {
  font-family: $fontFamily;
  color: $colorText;
  font-size: 1.4rem;
  line-height: 1;
}
header {
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
main {
  min-width: 320px;
  height: 100%;
  min-height: 100%;
  word-break: break-all;
}
a {
  color: $colorLink;
  cursor: pointer;
}
#root {
  height: 100%;
}
