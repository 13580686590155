@charset "utf-8";

// 01.SETTING

// ===== VARIABLE ===============================

// Directory Path
$imgPath: "../images";

// Font Size

// Font Family
$fontFamily: "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3",
  "ＭＳ Ｐゴシック", Arial, "Helvetica Neue", Helvetica, sans-serif;
$fontFamily2: Arial, "Helvetica Neue", Helvetica, "Hiragino Kaku Gothic Pro",
  メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;

// Color
$colorText: #333333;
$colorLink: #333333;
$colorWhite: #fff;

// client certificated color
$bg-neutral-white: #ffffff;
$bg-neutral-lighter: #f5f5f5;
// $bg-primary-main: #00796b;
// $border-primary-main: #00796b;
$bg-primary-main: #17a1e6;
$border-primary-main: #17a1e6;
$border-outline: #e0e0e0;
$content-neutral-dark: #424242;
$content-neutral-light: #616161;
$content-neutral-white: #ffffff;
// $content-primary-main: #00796b;
$content-primary-main: #17a1e6;
$content-error-main: #f44336;

// Height
$headerH: 50px;
$headerH02: 60px;
$bottomP: 100px;

// MediaQuery
// ブレイクポイント定義
$breakpoints: (
  // スマートフォン（小）
  "xs": 320px,
  // スマートフォン（標準）
  "sm": 375px,
  // タブレット（縦）
  "md": 768px,
  // タブレット（横）/小型PC
  "lg": 1024px,
  // 大型PC
  "xl": 1200px
);

// 既存の$mediaWは後方互換性のために残しておく
$mediaW: map-get($breakpoints, "lg");

// ===== EXTEND =================================

// ===== MIXIN ==================================

// メディアクエリー用mixin
@mixin mq($size) {
  @if map-has-key($breakpoints, $size) {
    @media screen and (min-width: #{map-get($breakpoints, $size)}) {
      @content;
    }
  } @else {
    @warn "指定されたブレイクポイント #{$size} は定義されていません。"
    + "利用可能な値: #{map-keys($breakpoints)}";
  }
}

// max-width用のmixin
@mixin mq-down($size) {
  @if map-has-key($breakpoints, $size) {
    @media screen and (max-width: #{map-get($breakpoints, $size) - 1px}) {
      @content;
    }
  } @else {
    @warn "指定されたブレイクポイント #{$size} は定義されていません。"
    + "利用可能な値: #{map-keys($breakpoints)}";
  }
}

// Clearfix
@mixin clearfix {
  &:after {
    content: "";
    height: 0;
    display: block;
    clear: both;
  }
}

// sidePadding
@mixin sidePadding {
  padding-left: 15px;
  padding-right: 15px;
}

// Scrollbar Style
@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    background: #e6e6e6;
  }

  &::-webkit-scrollbar-thumb {
    background: #b7b7b7;
  }
}

// font-sizeを数値で指定して、remで返すmixin
@mixin font-size($font-size) {
  font-size: ($font-size / 10) + rem;
}

@mixin line-height($line-height) {
  line-height: ($line-height / 10) + rem;
}

@mixin content-en-2xl-bold {
  @include font-size(20);
  font-weight: 700;
  @include line-height(36);
  letter-spacing: 0;
}

@mixin content-4xl-bold {
  @include font-size(24);
  font-weight: 700;
  @include line-height(36);
  letter-spacing: 0;
}

@mixin content-large-regular {
  @include font-size(16);
  font-weight: 500;
  @include line-height(24);
  letter-spacing: 0;
}

@mixin content-large-bold {
  @include font-size(16);
  font-weight: 700;
  @include line-height(24);
  letter-spacing: 0;
}

@mixin content-xl-large {
  @include font-size(18);
  font-weight: 700;
  @include line-height(27);
  letter-spacing: 0;
}

@mixin content-base-regular {
  @include font-size(14);
  font-weight: 500;
  @include line-height(21);
  letter-spacing: 0;
}

@mixin content-x-small-regular {
  @include font-size(12);
  font-weight: 500;
  @include line-height(18);
  letter-spacing: 0;
}

@mixin content-small-regular {
  @include font-size(13);
  font-weight: 500;
  @include line-height(19.5);
  letter-spacing: 0;
}

@mixin content-3xs-regular {
  @include font-size(10);
  font-weight: 500;
  @include line-height(15);
  letter-spacing: 0;
}

@mixin component-button-large {
  @include font-size(15);
  font-weight: 700;
  @include line-height(15);
  letter-spacing: 0;
}
