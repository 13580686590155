@import "../../variables.scss";

.sec_dialog01 {
  padding-top: 50px;
  margin-bottom: 100px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 1px 0;
}

@media screen and (min-width: $mediaW) {
  .sec_dialog01 {
    padding-right: calc((100vw - #{$mediaW} + 32px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
}

.sec_dialog01_text {
  padding: 26px 16px 20px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.7;
  text-align: center;
}

.sec_dialog01_btn {
  text-align: center;
  padding: 0 8px 16px;
  display: flex;
}

.glb_btn03 {
  display: inline-block;
  background: #fff;
  color: #17a1e6;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 15px 15px 14px;
  border-radius: 4px;
  border: 1px solid rgba(23, 161, 230, 0.5);
  opacity: 1;
  transition: opacity 0.1s linear;
  cursor: pointer;
  flex: 1;
  margin: 0 8px;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
}

.glb_btn01 {
  display: inline-block;
  background: #17a1e6;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 14px 24px 13px;
  border-radius: 5px;
  border: 1px solid #17a1e6;
  opacity: 1;
  transition: opacity 0.1s linear;
  text-align: center;
  cursor: pointer;
  flex: 1;
  margin: 0 8px;
  flex-shrink: 1;
  flex-grow: 1;
  white-space: nowrap;
}
