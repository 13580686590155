@import "../../variables.scss";
$imgPath: "../../assets/images";

.sec_check01 {
  padding-top: $headerH;
  padding-bottom: $bottomP;
}

.glb_list01 {
  margin-bottom: 8px;
  background: #fff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  > li {
    background: #fff;
    > a {
      display: block;
      padding-bottom: 13px;
    }
    + li {
      border-top: 1px solid #e6e6e6;
    }
    &:last-child.glb_list01_btn {
      padding-top: 16px;
      > a {
        display: inline-block;
      }
    }
    &.type_error {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.6;
      padding: 15px 16px 13px;
      color: #ff3333;
      background: rgba(255, 51, 51, 0.1);
    }
  }
  &-in {
    display: flex;
    align-items: center;
    + .glb_list01_img {
      padding-top: 0;
    }
    .type_end {
      margin-left: auto;
    }
    > .glb_btn03:last-child {
      margin-right: 16px;
    }
    .glb_list01_img {
      width: 100px;
      padding: 16px 0 16px 16px;
      img {
        width: 100px;
      }
    }
    .glb_list01_text {
      flex: 1;
    }
    .glb_switch01 {
      margin-right: 16px;
      margin-left: auto;
    }
  }
  &_text {
    display: block;
    padding: 17px 16px 16px;
    font-size: 1.7rem;
    line-height: 1.5;
    + .glb_list01_text {
      padding-top: 0;
    }
  }
  &_none {
    text-align: center;
    padding: 50px 16px;
    color: #666;
    font-size: 1.5rem;
  }
  &_zero {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    background: none;
    box-shadow: none;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.8rem;
    line-height: 2;
    &_ttl {
      font-size: 2rem;
      font-weight: bold;
      + .glb_list01_zero_text {
        margin-top: 12px;
      }
    }
    &_text {
      font-size: 1.5rem;
    }
  }
  &_main {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
    padding: 15px 16px 0;
  }
  &_ttl {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
    padding: 14px 16px 0;
    + .glb_box01_form {
      padding-top: 12px;
    }
    &_label {
      color: #666;
      margin-left: 10px;
      font-size: 1.1rem;
      display: inline-block;
      position: relative;
      bottom: 1px;
      border: 1px solid #ccc;
      padding: 2px 5px 1px;
      border-radius: 2px;
    }
  }
  &_sub {
    font-size: 1.4rem;
    line-height: 1.5;
    color: #333333;
    padding: 5px 16px 13px;
  }
  &_info {
    padding: 0 16px 16px;
    &.type_attent {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 60px 16px 64px;
      color: #e51717;
    }
  }
  &_btn {
    text-align: center;
    padding: 0 16px 16px;
  }
  &_cap {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 9px;
  }
  &_help {
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 16px 16px 14px;
    color: #666;
  }
  &_box {
    padding: 0;
  }
  &_img {
    padding: 16px;
    img,
    canvas {
      width: 100%;
    }
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin-right: 12px;
      padding-right: 12px;
      position: relative;
      &::after {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        right: 0;
        width: 1px;
        height: 14px;
        background: rgba(0, 0, 0, 0.1);
        margin-top: -7px;
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  &.type_check {
    > li {
      cursor: pointer;
      position: relative;
      opacity: 0.7;
      transition: all 0.1s linear;
      .glb_list01_main,
      .glb_list01_sub {
        padding-left: 50px;
      }
      &::before {
        position: absolute;
        top: 43px;
        left: 18px;
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        margin-top: -10px;
        background: url(#{$imgPath}/common/icon_check_01.svg) no-repeat;
        opacity: 0.5;
        transition: opacity 0.1s linear;
      }
      + li {
        border-top-color: #b3b3b3;
        &.is_check {
          border-top-color: rgba(0, 0, 0, 0.1);
        }
      }
      &.is_check {
        opacity: 1;
        background: #dcf0fa;
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: $mediaW) {
  .glb_list01 > li {
    padding-right: calc((100vw - #{$mediaW}) / 2);
    padding-left: calc((100vw - #{$mediaW}) / 2);
  }
  .glb_list01 > li.type_error {
    padding-right: calc((100vw - #{$mediaW} + 32px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
  .glb_list01.type_check > li::before {
    left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
}

/* ---------- glb-list02 ---------- */
.glb_list02 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  > li {
    > a {
      display: block;
      padding-bottom: 13px;
    }
    + li {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &_ttl {
    padding: 7px 12px 6px;
    font-size: 1.4rem;
    line-height: 1.3;
    letter-spacing: 0.05em;
    color: #4d4d4d;
  }

  &_main {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5;
    color: #333333;
    padding: 12px 12px 0;
  }
  &_sub {
    font-size: 1.3rem;
    line-height: 1.5;
    color: #333333;
    padding: 5px 12px 12px 13px;
  }
}

/* ---------- glb-box02 ---------- */
.glb_box02 {
  left: 0;
  bottom: 32px;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  padding: 14px 8px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  > .glb_btn02,
  > .glb_btn03 {
    flex: 1;
    margin: 0 8px;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

@media screen and (min-width: $mediaW) {
  .glb_box02 {
    padding-right: calc((100vw - #{$mediaW} + 16px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 16px) / 2);
  }
}

/* ---------- glb-btn02 ---------- */
.glb_btn02 {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 15px 15px 14px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  cursor: pointer;
  &.type_xs {
    font-weight: normal;
    font-size: 1.2rem;
    padding: 9px 10px 7px;
  }
}

/* ---------- glb-btn03 ---------- */
.glb_btn03 {
  display: inline-block;
  background: #fff;
  color: #17a1e6;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 15px 15px 14px;
  border-radius: 4px;
  border: 1px solid rgba(23, 161, 230, 0.5);
  opacity: 1;
  transition: opacity 0.1s linear;
  cursor: pointer;
  &.is_disable {
    cursor: default;
    opacity: 0.5;
  }
}

.glb_box02 .glb_btn03 {
  border-color: #fff;
}

/* ---------- glb-ttl01 ---------- */
.glb_ttl01 {
  background: #f5f5f5;
  color: #4d4d4d;
  font-size: 1.3rem;
  font-family: $fontFamily2;
  padding: 12px 16px 12px;
}

@media screen and (min-width: $mediaW) {
  .glb_ttl01 {
    padding-right: calc((100vw - #{$mediaW} + 32px) / 2);
    padding-left: calc((100vw - #{$mediaW} + 32px) / 2);
  }
}

.footer {
  background-color: rgba(0, 0, 0, 0.5);
}
